<div id="app-side-menu" [ngClass]="{'show-menu': isShowMenu}">
    <div class="header">
        <img src="/assets/images/brand-header.svg" class="button" routerLink="/" (click)="closeMenu.emit()">
        <span class="material-icons close button" (click)="closeMenu.emit()">close</span>
    </div>
    <div class="link-group">
        <div fxLayout="row" fxLayoutAlign="flex-start center" class="link" [routerLink]="'/' + CoreRoute.DASHBOARD"
            (click)="closeMenu.emit()">
            <mat-icon class="mr-20">dashboard</mat-icon>dashboard
        </div>
        <div fxLayout="row" fxLayoutAlign="flex-start center" class="link" [routerLink]="'/' + CoreRoute.REPORTS"
            (click)="closeMenu.emit()">
            <mat-icon class="mr-20">bar_chart</mat-icon>reports
        </div>
        <div fxLayout="row" fxLayoutAlign="flex-start center" class="link" [routerLink]="'/' + CoreRoute.LOG"
            (click)="closeMenu.emit()">
            <mat-icon class="mr-20">list_alt</mat-icon>log
        </div>
        <div fxLayout="row" fxLayoutAlign="flex-start center" class="link" [routerLink]="'/' + CoreRoute.RIPENING"
            (click)="closeMenu.emit()">
            <mat-icon class="mr-20">fire_extinguisher</mat-icon>ripening
        </div>
        <div fxLayout="row" fxLayoutAlign="flex-start center" class="link" [routerLink]="'/' + CoreRoute.WATER_MANAGEMENT"
            (click)="closeMenu.emit()">
            <mat-icon class="mr-20">water_drop</mat-icon>water management
        </div>
        <div fxLayout="row" fxLayoutAlign="flex-start center" class="link" [routerLink]="'/' + CoreRoute.SETTINGS"
            (click)="closeMenu.emit()">
            <mat-icon class="mr-20">settings</mat-icon>preferences
        </div>
        <div *ngIf="isSuperAdmin" fxLayout="row" fxLayoutAlign="flex-start center" class="link"
            [routerLink]="'/' + CoreRoute.BACKOFFICE" (click)="closeMenu.emit()">
            <mat-icon class="mr-20">support_agent</mat-icon>backoffice
        </div>

    </div>
    <div class="version">
        {{currentApplicationVersion}}
    </div>
</div>