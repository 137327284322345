import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'findInArray',
})
export class FindInArrayPipe implements PipeTransform {
	transform(array: any[], value: string | number, field: string, returnField: string, returnIfNull?: string): any  {
		if (value) {
			const find = array?.find((f) => f[field] === value);
			return find ? find[returnField] : returnIfNull ? returnIfNull : '';
		} else {
			return returnIfNull;
		}
	}
}
