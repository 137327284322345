import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/map';
import { AuthService } from '../services/auth.service';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private _authService: AuthService) { }

  canActivate() {
    const token = this._authService.getToken(true);
    this._authService.updateLoggedInUser(token);
    return this._authService.loggedInUser$.pipe((take(1)), map(user => !!user));
  }
}
