export class CoreRoute {
	static readonly DASHBOARD: string = 'dashboard';
	static readonly LOGIN: string = 'login';
	static readonly BACKOFFICE: string = 'backoffice';
	static readonly SETTINGS: string = 'settings';
	static readonly REPORTS: string = 'reports';
	static readonly LOG: string = 'log';
	static readonly SENSOR: string = 'sensor';
	static readonly ZONE: string = 'zone';
	static readonly RIPENING: string = 'ripening';
	static readonly WATER_MANAGEMENT: string = 'water-management';
}
