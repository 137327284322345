import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateToNumber'
})
export class DateToNumberPipe implements PipeTransform {

  transform(value: Date,  reduce?: Date): any {
    if(reduce) {
      return Math.floor((new Date(value).getTime() / 1000) - (new Date(reduce).getTime() / 1000));
    }
    return new Date(value).getTime() / 1000;
  }

}
