import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Zone, Alert } from '../models/zone';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { ActionLog } from '../models/actionLog';

@Injectable({
	providedIn: 'root',
})
export class ZoneService {
	constructor(private _api: ApiService) {}

	public zones(slim: boolean = false) {
		return this._api.get<Zone[]>('/zone', slim ? { slim: slim } : undefined);
	}

	public getZone(zoneId: string) {
		return this._api.get<Zone>(`/zone/${zoneId}`);
	}

	public alerts(zoneId: string) {
		return this._api.get<Alert[]>(`/zone/${zoneId}/alert`);
	}

	public setAlert(zoneId: string, alert: Alert) {
		return this._api.post<any>(`/zone/${zoneId}/alert`, alert);
	}

	public updateAlert(zoneId: string, alert: Alert) {
		return this._api.post<any>(`/zone/${zoneId}/alert`, alert);
	}

	public notes(zoneId: string) {
		return this._api.get<any>(`/zone/${zoneId}/note`);
	}

	public addNote(zoneId: string, content: any) {
		return this._api.post<any>(`/zone/${zoneId}/note`, content);
	}

	public updateNote(zoneId: string, note: any, noteId: string) {
		return this._api.put<any>(`/zone/${zoneId}/note/${noteId}`, note);
	}

	public deleteNote(zoneId: string, noteId: string) {
		return this._api.delete<any>(`/zone/${zoneId}/note/${noteId}`);
	}

	public rules(zoneId: string) {
		return this._api.get<any>(`/zone/${zoneId}/rule`);
	}

	public setRule(zoneId: string, rule: any) {
		return this._api.post<any>(`/zone/${zoneId}/rule`, rule);
	}

	public updateRule(zoneId: string, rule: any) {
		return this._api.put<any>(`/zone/${zoneId}/rule/${rule._id}`, rule);
	}

	public deleteRule(zoneId: string, ruleId: string) {
		return this._api.delete<any>(`/zone/${zoneId}/rule/${ruleId}`);
	}

	public getCycles(zoneId) {
		return this._api.get<any>(`/zone/${zoneId}/cycle`);
	}

	public postCycle(zoneId: string, obj: any) {
		return this._api.post<any>(`/zone/${zoneId}/cycle`, obj);
	}

	public updateCycle(zoneId: string, cycleId: string, obj: any) {
		return this._api.put<any>(`/zone/${zoneId}/cycle/${cycleId}`, obj);
	}

	public notifications() {
		return this._api.get<any>('/notification');
	}

	public hideNotification(notificationId) {
		return this._api.put<any>(`/notification/${notificationId}/hide`);
	}

	public armNotification(notificationId) {
		return this._api.put<any>(`/notification/${notificationId}/arm`);
	}

	public reports(request) {
		let params = new HttpParams();
		if (request.from) {
			params = params.append('from', request.from);
		}
		if (request.to) {
			params = params.append('to', request.to);
		}
		if (request.type) {
			params = params.append('type', request.type);
		}
		return this._api.get<any>(`/zone/${request.zoneId}/report`, params);
	}

	public log(request, zoneId?: string, categories?) {
		let params = new HttpParams();
		if (request.from) {
			params = params.append('from', request.from);
		}
		if (request.to) {
			params = params.append('to', request.to);
		}
		if (zoneId) {
			params = params.append('zone', zoneId);
		}
		if (categories) {
			params = params.append('category', categories);
		}
		return this._api.get<any>(`/action-log`, params).pipe(map((res) => res.actionLog.map((x) => new ActionLog(x))));
	}

	public csvExport(request) {
		let params = new HttpParams();
		if (request.from) {
			params = params.append('from', request.from);
		}
		if (request.to) {
			params = params.append('to', request.to);
		}
		if (request.type) {
			params = params.append('type', request.type);
		}
		return this._api.get<any>(`/zone/${request.zoneId}/export`, params, 'text');
	}

	public setDayPart(zoneId: string, obj: any) {
		return this._api.post<any>(`/zone/${zoneId}/daypart`, obj);
	}

	public updateDayPart(zoneId: string, obj: any) {
		return this._api.put<any>(`/zone/${zoneId}/daypart`, obj);
	}

	public deleteDayPart(zoneId: string) {
		return this._api.delete<any>(`/zone/${zoneId}/daypart`);
	}

	public sensorPair(zoneId: string, sensorKind: string) {
		return this._api.post<any>(`/zone/${zoneId}/pair/${sensorKind}`);
	}

	public uploadAttachment(zoneId: string, file: File) {
		const formData = new FormData();
		formData.append('file', file, file.name);
		return this._api.post(`/zone/${zoneId}/note/attachment`, formData);
	}

	public deleteAttachment(zoneId: string, noteId: string, attachmentId: string) {
		return this._api.delete(`/zone/${zoneId}/note${noteId ? `/${noteId}` : ``}/attachment/${attachmentId}`);
	}

	public getAttachment(src: string) {
		return this._api.get(src);
	}
}
