import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'timer',
})
export class TimerPipe implements PipeTransform {
	transform(value: number, timer: boolean, message?: string): string {
		if (value < 0) {
			return message;
		}
		if (timer) {
			const hours = Math.floor(value / 3600);
			const minutes = Math.floor((value - hours * 3600) / 60);
			const seconds = value - hours * 3600 - minutes * 60;
			if (hours === 0 && minutes === 0 && seconds === 0) {
				return message;
			}
			return `${hours > 0 ? hours?.toString()?.padStart(2, '0') : 0}:${minutes > 0 ? minutes?.toString()?.padStart(2, '0') : 0}:${
				seconds > 0 ? seconds?.toString()?.padStart(2, '0') : 0
			} `;
		} else {
			const hours: number = Math.floor(value / 60);
			const hourMsg = hours > 1 ? 'hours' : 'hour';
			const minutes: number = Math.floor(value) % 60;
			const minutesMsg = minutes > 1 ? 'minutes' : 'minute';
			if (hours || minutes) {
				return `${hours > 0 ? `${hours} ${hourMsg}` : ''} ${minutes > 0 ? ` and ${minutes} ${minutesMsg}` : ''}`;
			} else {
				return message;
			}
		}
	}
}
