import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from './services/auth.service';
import { IconService } from './services/icon.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnDestroy {
	showMenu: boolean = false;
	title = 'indorz-ui-web';
	isLoggedIn: boolean;
	private unsubscribeAll: Subject<void> = new Subject<void>();

	constructor(private authService: AuthService, private iconService: IconService) {
		this.authService.loggedInUser$.pipe(takeUntil(this.unsubscribeAll)).subscribe((result) => {
			this.isLoggedIn = !!result;
		});

		this.iconService.registerIcons();
	}

	ngOnDestroy(): void {
		this.unsubscribeAll.next();
		this.unsubscribeAll.complete();
	}
}
