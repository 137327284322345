import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class IconService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}


  registerIcons(): void {
    this.matIconRegistry.addSvgIcon(
      'fan-auto',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/fan_auto.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fan-high',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/fan_high.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fan-low',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/fan_low.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fan-mid',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/fan_mid.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'mode-cool',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/mode_cool.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'mode-dry',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/mode_dry.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'mode-fan',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/mode_fan.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'mode-heat',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/mode_heat.svg')
    );
  }
}
