import { Pipe, PipeTransform } from '@angular/core';
import { Sensor } from '../models/sensor';

@Pipe({
	name: 'findSensor',
})
export class FindSensorPipe implements PipeTransform {
	transform(id: string, sensors: Sensor[]): string {
		return sensors?.find((f) => f._id === id)?.name;
	}
}
