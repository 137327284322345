import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpConfigInterceptor} from './config/httpInterceptor';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SideMenuComponent} from './components/main-frame/side-menu/side-menu.component';
import {TopBarComponent} from './components/main-frame/top-bar/top-bar.component';
import {NotificationsComponent} from './components/main-frame/notifications/notifications.component';
import {SharedModule} from './modules/shared.module';
import {ConfirmDialogComponent} from './components/general/confirm-dialog/confirm-dialog.component';
import {DatePipe, DecimalPipe} from '@angular/common';
import {SocketIoService} from './services/socket-io.service';
import {EditNameDialogComponent} from './components/dialogs/edit-name-dialog/edit-name-dialog.component';


@NgModule({
    declarations: [
        AppComponent,
        NotificationsComponent,
        ConfirmDialogComponent,
        SideMenuComponent,
        TopBarComponent,
        EditNameDialogComponent,
    ],
    imports: [
        BrowserModule,
        SharedModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AppRoutingModule,
    ],
    providers: [
        SocketIoService,
        DatePipe, DecimalPipe,
        {provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true},
    ],
    entryComponents: [
        NotificationsComponent,
        EditNameDialogComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
