import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule, MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/slide-toggle';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SensorPipe } from '../pipes/sensor-display-name.pipe';
import { SensorIconPipe } from '../pipes/sensor-icon.pipe';
import { ImgFallbackDirective } from '../directives/img-fallback.directive';
import { SensorFormatPipe } from '../pipes/sensor-format.pipe';
import { MatTableModule } from '@angular/material/table';
import { ReplacePipe } from '../pipes/replace.pipe';
import { MatDividerModule } from '@angular/material/divider';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FilterPipe } from '../pipes/filter.pipe';
import { SensorValuePipe } from '../pipes/sensor-value.pipe';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxFilesizeModule } from 'ngx-filesize';
import { MatChipsModule } from '@angular/material/chips';
import { OverlayModule } from '@angular/cdk/overlay';
import { AuthImagePipe } from '../pipes/auth-image.pipe';
import { TruncatePipe } from '../pipes/truncate.pipe';
import { MatSortModule } from '@angular/material/sort';
import { ReadOnlyDirective } from '../directives/read-only.directive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { FindInArrayPipe } from '../pipes/find-in-array.pipe';
import { TimerPipe } from '../pipes/timer.pipe';
import { DateToNumberPipe } from '../pipes/date-to-number.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FindSensorPipe } from '../pipes/sensor.pipe';

@NgModule({
	declarations: [
		SensorPipe,
		SensorIconPipe,
		SensorFormatPipe,
		ImgFallbackDirective,
		ReplacePipe,
		FilterPipe,
		SensorValuePipe,
		AuthImagePipe,
		TruncatePipe,
		ReadOnlyDirective,
		FindInArrayPipe,
		TimerPipe,
		DateToNumberPipe,
		FindSensorPipe,
	],
	imports: [
		FormsModule,
		ReactiveFormsModule,
		MatSliderModule,
		MatToolbarModule,
		MatIconModule,
		MatSidenavModule,
		MatListModule,
		MatButtonModule,
		MatFormFieldModule,
		MatInputModule,
		MatCardModule,
		MatDialogModule,
		MatSlideToggleModule,
		MatSelectModule,
		MatRadioModule,
		CommonModule,
		MatTabsModule,
		MatProgressSpinnerModule,
		MatDatepickerModule,
		MatNativeDateModule,
		NgApexchartsModule,
		MatTooltipModule,
		MatTableModule,
		MatDividerModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatStepperModule,
		MatSnackBarModule,
		NgxFilesizeModule,
		MatChipsModule,
		OverlayModule,
		MatSortModule,
		FlexLayoutModule,
		MatMenuModule,
		MatExpansionModule,
		MatRippleModule,
		MatCheckboxModule,
		MatButtonToggleModule,
	],
	exports: [
		FormsModule,
		ReactiveFormsModule,
		MatSliderModule,
		MatToolbarModule,
		MatIconModule,
		MatSidenavModule,
		MatListModule,
		MatButtonModule,
		MatFormFieldModule,
		MatInputModule,
		MatCardModule,
		MatDialogModule,
		MatSlideToggleModule,
		MatSelectModule,
		MatRadioModule,
		MatTabsModule,
		MatProgressSpinnerModule,
		MatDatepickerModule,
		MatNativeDateModule,
		NgApexchartsModule,
		MatTooltipModule,
		SensorPipe,
		FindSensorPipe,
		SensorIconPipe,
		SensorFormatPipe,
		ImgFallbackDirective,
		MatTableModule,
		ReplacePipe,
		FindInArrayPipe,
		MatDividerModule,
		MatPaginatorModule,
		FilterPipe,
		TimerPipe,
		DateToNumberPipe,
		SensorValuePipe,
		MatProgressBarModule,
		MatStepperModule,
		MatSnackBarModule,
		NgxFilesizeModule,
		MatChipsModule,
		OverlayModule,
		AuthImagePipe,
		TruncatePipe,
		MatSortModule,
		FlexLayoutModule,
		ReadOnlyDirective,
		MatMenuModule,
		MatExpansionModule,
		MatRippleModule,
		MatCheckboxModule,
		MatButtonToggleModule,
	],
	providers: [
		SensorPipe,
		FindSensorPipe,
		SensorFormatPipe,
		AuthService,
		FilterPipe,
		SensorValuePipe,
		AuthImagePipe,
		TruncatePipe,
		{
			provide: MAT_RADIO_DEFAULT_OPTIONS,
			useValue: { color: 'primary' },
		},
	],
})
export class SharedModule {}
