import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './config/auth.guard';
import { CoreRoute } from './core/route/core-route';

const routes: Routes = [
	{
		path: CoreRoute.LOGIN,
		loadChildren: () => import('./components/user/user.module').then((m) => m.UserModule),
	},
	{
		path: CoreRoute.DASHBOARD,
		loadChildren: () => import('./components/dashboard/dashboard.module').then((m) => m.DashboardModule),
		canActivate: [AuthGuard],
	},
	{
		path: CoreRoute.SENSOR,
		loadChildren: () => import('./components/sensor/sensor.module').then((m) => m.SensorModule),
		canActivate: [AuthGuard],
	},
	{
		path: CoreRoute.ZONE,
		loadChildren: () => import('./components/zone/zone.module').then((m) => m.ZoneModule),
		canActivate: [AuthGuard],
	},
	{
		path: CoreRoute.RIPENING,
		loadChildren: () => import('./components/ripening/ripening.module').then((m) => m.RipeningModule),
		canActivate: [AuthGuard],
	},
	{
		path: CoreRoute.WATER_MANAGEMENT,
		loadChildren: () => import('./components/water-management/water-management.module').then((m) => m.WaterManagementModule),
		canActivate: [AuthGuard],
	},
	{
		path: CoreRoute.REPORTS,
		loadChildren: () => import('./components/reports/reports.module').then((m) => m.ReportsModule),
		canActivate: [AuthGuard],
	},
	{
		path: CoreRoute.LOG,
		loadChildren: () => import('./components/log/log.module').then((m) => m.LogModule),
		canActivate: [AuthGuard],
	},
	{
		path: CoreRoute.SETTINGS,
		loadChildren: () => import('./components/settings/settings.module').then((m) => m.SettingsModule),
		canActivate: [AuthGuard],
	},
	{
		path: CoreRoute.BACKOFFICE,
		loadChildren: () => import('./components/backoffice/backoffice.module').then((m) => m.BackofficeModule),
		canActivate: [AuthGuard],
	},
	{ path: '', redirectTo: CoreRoute.DASHBOARD, pathMatch: 'full' },
	{ path: '**', loadChildren: () => import('./modules/page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule) },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: PreloadAllModules,
			relativeLinkResolution: 'legacy',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
